<template>
  <div
    class="wrapper"
    :class="{ resizing: isResizing }"
    @mouseup="endResize"
  >
    <header>
      <button
        class="aside"
        :class="{ opened: isAsideOpened }"
        @click="toggleAside(!isAsideOpened)"
      >
        <span />
        <span />
        <span />
      </button>

      <NuxtLink to="/" class="logo">
        <SvgLogoHelp />
      </NuxtLink>

      <nav :class="{ opened: isMenuOpened }">
        <ul class="row-1">
          <li
            v-for="{ path, i18nKey } of MENU_ROW_1"
            :key="path"
          >
            <NuxtLink :to="localePathWithSlash(path)">
              {{ $t(i18nKey) }}
            </NuxtLink>
          </li>
        </ul>

        <ul class="row-2">
          <li
            v-for="{ path, i18nKey } of MENU_ROW_2"
            :key="path"
          >
            <NuxtLink :to="localePathWithSlash(path)">
              {{ $t(i18nKey) }}
            </NuxtLink>
          </li>
        </ul>
      </nav>

      <button
        class="menu"
        :class="{ opened: isMenuOpened }"
        @click="toggleMenu(!isMenuOpened)"
      >
        <span />
        <span />
        <span />
      </button>
    </header>

    <aside
      :style="{ width: `${asideWidth}px` }"
      :class="{ opened: isAsideOpened }"
    >
      <ul class="tabs">
        <li
          v-for="(tab, label) of tabs"
          :key="label"
          :class="{ active: tab.active }"
          class="tab"
        >
          <button @click="onTabClick(tab)">
            <component :is="tab.icon" />
            <span>{{ label }}</span>
          </button>
        </li>
      </ul>

      <ul class="tabs-content">
        <li class="tab-content" :class="{ active: tabs.Contents.active }">
          <nav>
            <NavItem
              v-for="node of treeNested.children"
              :key="node.id"
              :node
              :active-id
              @click="setActiveId"
            />
          </nav>
        </li>

        <li class="tab-content" :class="{ active: tabs.Search.active }">
          <input
            v-model="search"
            type="search"
            placeholder="Search table of contents..."
          >
        </li>
      </ul>
    </aside>

    <hr @mousedown="startResize">

    <main :style="{ width: mainWidth }">
      <h1>{{ title }}</h1>
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
// TODO: open navItems leads to active item
// FIXME: store tree json locally. it updates once per day
// FIXME: replace treeFlat by function

import type { FlatTree } from '~/types/tree';
import type { UnwrapRef } from 'vue';
import { PATHS } from '~/constants/nav-items';
import treeNested from '../data/tree_nested.json';
import treeFlat from '../data/tree_flat.json';
import NavItem from '~/components/NavItem.vue';
import getValidRange from '~/utils/getValidRange';
import { useHelpStore } from '~/stores/HelpStore';
import debounce from 'lodash.debounce';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

const localePathWithSlash = useLocalePathWithSlash();

const MENU_ROW_1 = [
  PATHS.CONTACTS_US,
  PATHS.BLOG,
  PATHS.SUPPORT,
];
const MENU_ROW_2 = [
  PATHS.PRODUCT,
  PATHS.RESOURCES,
  PATHS.NEWSROOM,
  PATHS.ABOUT,
  PATHS.PARTNERS,
  PATHS.CAREERS,
];
const RESIZER_WIDTH = 8;

const isResizing = ref(false);
const asideWidth = ref(400);
const mainWidth = computed(() => `calc(100% - ${asideWidth.value}px - ${RESIZER_WIDTH}px)`);

const resizeHandler = ({ clientX }: MouseEvent) => {
  asideWidth.value = getValidRange(clientX, 600, 250);
};
const startResize = () => {
  isResizing.value = true;
  document.addEventListener('mousemove', resizeHandler, { passive: true });
};
const endResize = () => {
  isResizing.value = false;
  document.removeEventListener('mousemove', resizeHandler);
};

type Keys = 'Contents' | 'Search';
type Icons = 'SvgContents' | 'SvgSearch';
type Tab = {
  [key in Keys]: {
    active: boolean
    icon: Icons
  }
};

const store = useHelpStore();
const route = useRoute();
const slug = computed<string | undefined>(() => Array.isArray(route.params.slug)
  ? route.params.slug[0]
  : route.params.slug
);

const tabs = reactive<Tab>({
  Contents: {
    active: true,
    icon: 'SvgContents',
  },
  Search: {
    active: false,
    icon: 'SvgSearch',
  },
});
const onTabClick = (tab: UnwrapRef<Tab[Keys]>) => {
  for (const key in tabs) {
    const currentTab = tabs[key as Keys];

    currentTab.active = currentTab === tab;
  }
};

const activeId = ref<null | number>(null);
const setActiveId = (id: number) => {
  activeId.value = id;
  toggleAside(false);
};

watchSyncEffect(() => {
  if (Number.isFinite(activeId.value) || typeof slug.value !== 'string') return;

  const node = (treeFlat as FlatTree)[slug.value];

  if (node) {
    activeId.value = node.id;
  }
});

const title = computed(() => {
  const { helpPage: { title } } = store;
  let res = 'Object First Help';

  if (title) {
    res += `: ${title}`;
  }

  return res;
});
const search = computed({
  get: () => store.searchQuery,
  set: debounce(store.setSearchQuery, 500),
});

const isAsideOpened = ref(false);
const toggleAside = (value: boolean) => {
  isAsideOpened.value = value;
};

const isMenuOpened = ref(false);
const toggleMenu = (value: boolean) => {
  isMenuOpened.value = value;
};

useHead({
  title,
  meta: [
    { name: 'robots', content: 'noindex, nofollow' },
  ],
  bodyAttrs: {
    class: 'help',
  },
});
</script>

<!-- RESETS -->
<style lang="scss">
body.help {
  margin: 0;
  overflow: hidden;

  * {
    box-sizing: border-box;
    outline: none;
  }

  button {
    border: unset;
    background-color: unset;
    padding: 0;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}
</style>

<style scoped lang="scss">
$purple: #662E9C;
$phone: 768px;

@mixin media($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100vw;
  height: 100vh;

  overflow: hidden;

  @include media($phone) {
    position: relative;
  }

  &.resizing {
    header,
    aside,
    main {
      user-select: none;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 0 50px;
    border-bottom: 1px solid #909294;

    @include media($phone) {
      position: relative;
      padding: 15px 25px;
    }

    button.aside {
      display: none;

      @include media($phone) {
        display: block;
        position: relative;

        width: 42px;
        height: 34px;

        border-radius: 4px;
        border: 1px solid transparent;

        transition: all 0.15s ease;

        &:hover {
          background-color: #F5F5F5;
          border: 1px solid #dbdbdb;
        }

        span {
          display: block;
          position: absolute;
          left: 9px;

          width: 22px;
          height: 2px;

          background-color: $purple;

          transform-origin: center center;
          transition: all 0.15s ease;

          &:nth-child(1) {
            top: 8px;
            transform: rotate(0);
            transition:
              transform 0.2s ease,
              top 0.2s 0.2s ease;
          }

          &:nth-child(2) {
            transform: translateY(-50%);
            opacity: 1;
          }

          &:nth-child(3) {
            bottom: 8px;
            transform: rotate(0);
            transition:
              transform 0.2s ease,
              bottom 0.2s 0.2s ease;
          }
        }

        &.opened {
          background-color: #F5F5F5;
          border: 1px solid #dbdbdb;

          span {
            left: 12px;

            width: 15px;
            height: 4px;

            background-color: #4A4A4A;

            &:nth-child(1) {
              top: 14px;
              transform: rotate(45deg);
              transition:
                top 0.2s ease,
                transform 0.2s 0.2s ease;
            }

            &:nth-child(2) {
              opacity: 0;
              transition: opacity 0.2s ease;
            }

            &:nth-child(3) {
              bottom: 14px;
              transform: rotate(-45deg);
              transition:
                bottom 0.2s ease,
                transform 0.2s 0.2s ease;
            }
          }
        }
      }
    }

    .logo {
      svg {
        width: 280px;
      }
    }

    nav {
      @include media($phone) {
        height: 0;
        overflow: hidden;

        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 2;

        background-color: #fff;
        box-shadow: 0 10px 10px rgb(0 0 0 / 15%);

        transition: all 0.2s ease;

        &.opened {
          height: 330px;
        }
      }

      ul {
        display: flex;
        align-items: center;

        @include media($phone) {
          flex-direction: column;
        }

        li {
          a {
            display: block;
            text-decoration: none;
            font-family: 'Inter', sans-serif;

            color: #212529;
            text-shadow: 0 0 1px transparent;
            transition: color 0.3s ease;

            &:hover {
              color: $purple;
              text-shadow: 0 0 1px $purple;
            }
          }
        }

        &.row-1 {
          justify-content: flex-end;

          li {
            a {
              font-size: 14px;
              padding: 8px 10px;

              @include media($phone) {
                padding-left: 0;
                padding-right: 0;
              }
            }

            &:first-of-type {
              a {
                padding-left: 0;

                @include media($phone) {
                  margin-top: 4px;
                }
              }
            }

            &:last-of-type {
              a {
                padding-right: 0;

                @include media($phone) {
                  margin-bottom: 4px;
                }
              }
            }
          }
        }

        &.row-2 {
          justify-content: space-between;
          border-top: 2px solid rgba(33, 37, 41, 0.08);

          li {
            $offset-x: 15px;

            &:first-of-type {
              a {
                padding-left: 0;

                &::after {
                  left: 0;
                }
              }
            }

            &:last-of-type {
              a {
                padding-right: 0;

                &::after {
                  right: 0;
                }
              }
            }

            a {
              position: relative;
              padding: 8px $offset-x;

              @include media($phone) {
                padding-left: 0;
                padding-right: 0;
              }

              &:after {
                content: '';
                display: block;

                position: absolute;
                bottom: -4px;
                left: $offset-x;
                right: $offset-x;

                height: 2px;
                background-color: transparent;
                transition: all 0.15s ease;

                @include media($phone) {
                  left: 0;
                  right: 0;
                }
              }

              &:hover {
                &:after {
                  background-color: $purple;
                }
              }
            }
          }
        }
      }
    }

    button.menu {
      display: none;

      @include media($phone) {
        display: block;
        position: relative;
        z-index: 1;

        width: 40px;
        height: 30px;

        span {
          display: block;
          position: absolute;

          transform-origin: center center;

          width: 40px;
          height: 2px;

          background-color: $purple;

          &:nth-child(1) {
            top: 4px;
            transform: rotate(0);
            transition:
              transform 0.2s ease,
              top 0.2s 0.2s ease;
          }

          &:nth-child(2) {
            transform: translateY(-1px);
            opacity: 1;
          }

          &:nth-child(3) {
            bottom: 4px;
            transform: rotate(0);
            transition:
              transform 0.2s ease,
              bottom 0.2s 0.2s ease;
          }
        }

        &.opened {
          span {
            &:nth-child(1) {
              top: 14px;
              transform: rotate(-45deg);
              transition:
                top 0.2s ease,
                transform 0.2s 0.2s ease;
            }

            &:nth-child(2) {
              opacity: 0;
              transition: opacity 0.2s ease;
            }

            &:nth-child(3) {
              bottom: 14px;
              transform: rotate(45deg);
              transition:
                bottom 0.2s ease,
                transform 0.2s 0.2s ease;
            }
          }
        }
      }
    }
  }

  aside,
  main {
    height: calc(100% - 72px);
  }

  aside {
    display: flex;
    flex-direction: column;

    background-color: #F7F7F7;

    @include media($phone) {
      position: absolute;
      top: 75px;
      left: 0;
      right: 0;

      z-index: 1;

      width: 100% !important;

      transform: translateX(-100%);
      transition: all 0.2s ease;

      &.opened {
        transform: translateX(0);
      }
    }

    ul.tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      li.tab {
        width: 50%;

        &.active {
          button {
            background-color: #F7F7F7;

            svg {
              fill: $purple;
            }

            span {
              color: $purple;
            }

            &:before {
              width: 100%;
            }
          }
        }

        button {
          width: 100%;
          height: 100%;

          padding: 13px;

          cursor: pointer;

          display: flex;
          flex-direction: column;
          align-items: center;

          background-color: #F0F0F0;

          position: relative;

          svg {
            width: 15px;
            height: 15px;

            margin-bottom: 9px;

            transition: fill 0.15s ease;
          }

          span {
            font-size: 14px;
            font-weight: 700;
            color: #909294;

            transition: color 0.15s ease;
          }

          &:before {
            content: '';
            display: block;

            position: absolute;
            top: 0;
            width: 0;
            height: 3px;

            background-color: $purple;

            transition: width 0.3s ease;
          }

          &:hover {
            span {
              color: $purple;
            }

            &:before {
              width: 100%;
            }
          }
        }
      }
    }

    ul.tabs-content {
      overflow-y: auto;

      li.tab-content {
        display: none;

        padding: 15px 25px;

        &.active {
          display: block;
        }

        input[type="search"] {
          height: 34px;
          padding: 6px 12px;
          font-size: 14px;
          color: #555;
          border: 1px solid #ccc;
          border-radius: 4px;
          transition: border-color 0.15s ease;

          width: 100%;
          max-width: 250px;

          &:focus {
            border-color: $purple;
          }
        }

        nav {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  hr {
    margin: 0;
    margin-block: 0;
    border: unset;

    display: block;

    height: 100%;
    width: 8px;

    background-color: #F0F0F0;

    cursor: w-resize;

    position: relative;

    @include media($phone) {
      display: none;
    }

    &:after {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOS45NiAxMjIuODgiPjxwYXRoIGZpbGw9IiM5MDkyOTQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xhc3M9ImNscy0xIiBkPSJNMTUsMEExNSwxNSwwLDEsMSwwLDE1LDE1LDE1LDAsMCwxLDE1LDBabTAsOTIuOTNhMTUsMTUsMCwxLDEtMTUsMTUsMTUsMTUsMCwwLDEsMTUtMTVabTAtNDYuNDdhMTUsMTUsMCwxLDEtMTUsMTUsMTUsMTUsMCwwLDEsMTUtMTVaIj48L3BhdGg+PC9zdmc+);
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      width: 6px;
    }
  }

  main {
    padding: 0 15px 15px 15px;
    background-color: #fff;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    @include media($phone) {
      width: 100% !important;
    }

    h1 {
      font-family: 'Inter',sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #212529;
    }
  }

  ul {
    margin: 0;
  }
}
</style>
